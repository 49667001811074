@import "/src/data/styles.css";
@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@300&family=Roboto:wght@500&display=swap");
@import "./data/styles.css";

/* light theme colors */
.light-theme {
	/* ------- colors ------- */
	--primary-color: #1c1c1e;
	--secondary-color: #65656d;
	--tertiary-color: #acacb4;
	--quaternary-color: #e4e4e7;
	--link-color: #14b8a6;
	/* --border-color: #e9e6e6; */
	--border-color: #f4f4f5;
	--background: #fff;
	--hover-background: #f8f8f8;
	--secondary-background: #fbfdfd;
	/* ---------------------- */

	/* ------- fonts ------- */
	--primary-font: "Heebo", sans-serif;
	--secondary-font: "Roboto", sans-serif;
	/* --------------------- */
}

/* dark theme colors */
.dark-theme {
	/* ------- colors ------- */
	--primary-color: #d5d5df;
	--secondary-color: #a1a1a1;
	--tertiary-color: #9c9c9c;
	--card-color: #27262c;
	--quaternary-color: #1e1e1f;
	--link-color: #14b8a6;
	--hover-background: #0e182c;
	--border-color: #131313;
	--background: #131313;
	--secondary-background: #131313;
}

body {
	margin: 0;
	font-family: var(--primary-font);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: var(--background);
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}


::-webkit-scrollbar {
	width: 10px;
}

::-webkit-scrollbar-track {
	background-color: var(--secondary-background);
}

::-webkit-scrollbar-thumb {
	background-color: #383636;
	border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
	background-color: rgb(0, 0, 0);
}

.page-content {
	background-color: var(--secondary-background);
	position: relative;
}

.page-content:before,
.page-content:after {
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	width: calc((100% - 1200px) / 2);
	background-color: var(--background);
	border: 1px solid var(--border-color);
}

.page-content:before {
	left: 0;
	z-index: 1;
}

.page-content:after {
	right: 0;
	z-index: 1;
}

.content-wrapper {
	max-width: 1000px;
	margin: 0 auto;
}

.title {
	color: var(--primary-color);
	font-family: var(--secondary-font);
	font-size: 45px;
	font-weight: 700;
	width: 70%;
}

.image {
	width: 100%;
}

.subtitle {
	padding-top: 10px;
	color: var(--secondary-color);
	font-size: 16px;
	line-height: 28px;
	width: 70%;
	margin-top: 25px;
}

.section-title {
	color: var(--primary-color);
	font-family: var(--secondary-font);
	font-size: 25px;
	font-weight: 700;
	width: 70%;
	padding-bottom: 0.5em;
	padding-left: 0.5em;
}

.page-footer {
	position: block;
}

.page-footer::before {
	content: "";
	position: absolute;
	left: 0;
	right: 0;
	border-top: 2px solid var(--tertiary-color);
}

@media (max-width: 1270px) {
	.page-content:before,
	.page-content:after {
		width: calc((100% - 90%) / 2);
	}

	.content-wrapper {
		max-width: 90%;
		padding-left: 10%;
		padding-right: 10%;
	}
}

@media (max-width: 1024px) {
	.page-content:before,
	.page-content:after {
		width: calc((100% - 950px) / 2);
	}

	.content-wrapper {
		max-width: 980px;
	}
}

@media (max-width: 800px) {
	.content-wrapper {
		max-width: 90%;
		margin: 0 auto;
		padding: 0 10px;
	}

	.page-content:before,
	.page-content:after {
		display: none;
	}

	.title {
		width: 100%;
	}

	.subtitle {
		width: 100%;
	}
}
